<template>
  <div>
    <div class="backgroundOV"></div>
    <v-card outlined>
      <v-card-title class="titleLarge">Nicht-zählendes Rechnen</v-card-title>
    </v-card>
    <br />
    <br />
    <v-card outlined>
      <v-card-title class="titleSmaller"
        >Was erwartet Sie im Inhaltsbereich nicht-zählendes Rechnen?
      </v-card-title>
      <v-card-text>
        Dem nicht-zählenden Rechnen liegen drei wesentliche Aspekte zugrunde.
        Diese gelten weithin als Grundlage zur Erarbeitung des nicht-zählenden
        Rechnens. Daher wird im Hintergrundwissen auf diese drei Bereiche
        eingegangen:
        <ol>
          <li>Wieso ist zählendes Rechnen problematisch?</li>
          <li>Teil-Ganzes-Verständnis</li>
          <li>Aufbau flexibler Rechenstrategien</li>
        </ol>
        Im Anschluss werden an einigen Schüler:innenbeispielen Diagnose- und
        Förderaufgaben gestellt, sodass Sie die Facetten des Hintergrundwissens
        exemplarisch anwenden können.
        <br />
        Den Abschluss bildet eine Kompetenzübersicht, sodass transparent wird,
        was für „nicht-zählendes Rechnen“ zentral ist.
      </v-card-text>
    </v-card>

    <v-row no-gutters justify="center" align="center">
      <p class="white--text" style="z-index: 1; padding-top: 15px">
        Im unteren Bereich finden Sie die Navigation.
      </p>
    </v-row>
    <v-row no-gutters justify="center" align="center">
      <v-icon x-large class="white--text">mdi-arrow-down</v-icon>
    </v-row>
    <AppBottomNavNZR next="/nicht-zaehlendes-rechnen/hintergrundwissen" />
  </div>
</template>

<script>
import AppBottomNavNZR from "@/common/AppBottomNavNZR";
export default {
  components: {
    AppBottomNavNZR,
  },
};
</script>
<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
